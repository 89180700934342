i{
	font-size:1.5rem;
}
p i, .ck-content i {
	font-size:1.2rem;
}
.at-top{
	align-self:flex-start;
	margin-top:11px;
	&.no-marge{
		margin-top:0px;
	}
}
.at-bottom{
	align-self:flex-end;
	margin-bottom:4px;
}
.offset-label{
	padding-left:255px;
}
.flex-label,
fieldset{
	@extend .flex-label;
	flex-wrap:wrap;
	align-items:center;
	label,
	legend,
	.label{
		text-align:right;
		padding-right:15px;
		flex:0 0 240px;
		float:none;
		& + span{
			align-self:flex-start;
		}
	}
	&:last-child{
		margin-bottom:0;
	}
}
label,
fieldset > legend,
.label{
	font-size:1.3rem;
	font-weight:bold;
	display:block;
	margin-bottom:0;
	&.text-normal{
		font-weight:normal;
	}
}
& > div{
	margin-bottom:20px;
}
.field,
.field-wrap{
	border:1px solid $border-color;
	border-radius:$border-radius;
	color:$color-gris-texte;
	max-width:100%;
	flex-grow:1;
	line-height:3rem;
	align-self:flex-start;
	margin:4px 0;
	background:$color-blanc;
	&.auto{
		flex:0 0 auto;
	}
	&::placeholder{
		color:$color-gris-40;
		opacity:0.75;
	}
	&.small,
    &.xsmall,
	&.xxsmall,
	&.medium{
		font-size:100%;
		flex-grow:0;
	}
	&.xxsmall{
        width: 60px;
    }
    &.xsmall{
        width: 140px;
    }
	&.small{
		width:280px;
	}
	&.medium{
		width:470px;
	}
}
.field{
	padding:0 10px;
	font-weight:bold;
	&[type="file"]{
		padding-left:0;
	}
	+ .btn{
		margin-left:10px;
	}
	&.react-dropdown-tree-select{
		padding:0 0 0 0;
		ul{
			margin-bottom:0;
			list-style:none;
			padding-left:0;
			&.tag-list{
				& > li {
					display:inline-block;
				}
				.tag{
					background-color: $color-gris-20;
					border-radius: 10px;
					margin: 0px 5px;
					display:inline-block;
					padding:0 5px;
					line-height: 2rem;;
					button{
						border:none;
						background:transparent;
					}
					&-remove{
						font-size:1.5rem;
						font-weight:bold;
						color:$color-error;
					}
				}
			}
		}
		input[type="text"]{
			width:100%;
			border:none;
			background:transparent;
			position:relative;
		}
		.dropdown-trigger.arrow{
			position:relative;
			display:block;
			width:100%;
			color:$color-gris-texte;
			&:hover,
			&:focus{
				text-decoration:none;
			}
			&:before{
				@extend .fontello-base;
    			@extend .icon-general-carat;
    			position:absolute;
    			right:10px;
    			bottom:10px;

			}
		}
		.dropdown-content{
			padding:0 10px;
			overflow-y: auto;
			label{
				text-align:left;
				cursor:pointer;
				.node-label:before{
					color:$color-gris-40;
				}
			}
			.infinite-scroll-component {
				overflow: visible !important;
			}
			.node {
				& > label {
					.node-label {
						font-size: 1rem;
					}
				}
			}
			.tree{
				display:flex;
				& > .toggle{
					order:2;
					position:relative;
					top:7px;
					@extend .fontello-base;
					@extend .icon-right-open;
					&.expanded{
						transform:rotate(90deg);
						top:0;
						left:-7px;
					}
				}
				& > label{
					order:1;
					padding-right:0;
				}
			}
		}
		.disabled {
			background-color:$color-gris-10;
		}
	}
	&:disabled {
		background-color:$color-gris-10;
	}	
}
.react-select-container {
	width: 200px;
	padding:0px 0px 0px 10px;
	&.border-danger {
		padding:0px 0px 0px 10px;
	}
	&.react-select--is-disabled {
		background-color:$color-gris-10;
	}

	.react-select__value-container {
		padding:0px;
		& > div {
			margin:0px;
			padding:0px;
		}
		.react-select__single-value {
			color:$color-gris-texte;
		}
	}
	.react-select__indicator {
		padding:0px;
	}
	.react-select__indicator-separator {
		width:0px;
	}
	.react-select__control {
		border:0;
		border-radius:0;
		min-height:0px;		
	}
	.react-select__control--is-disabled {
		background-color:$color-gris-10;
	}
	.react-select__control--is-focused {
		box-shadow:none;
	}
	.react-select__placeholder {
		color:$color-gris-40;
		margin:0px;
	}
	.react-select__menu {
		margin:1px 0px 0px -10px;
		border-radius:0;
		.react-select__menu-list {
			padding:0px;
			.react-select__option {
				padding:0px 0px 0px 5px;
			}
			.react-select__option--is-focused {
				background-color:$color-gris-20;
				color:$color-gris-60;
			}
		}
	}
	.react-select__multi-value {
		background-color:transparent;
	}
	.react-select__multi-value__label {
		padding:0px;
		white-space: normal;
	}
	.react-select__multi-value__remove {
		color:$color-error;
	}
}
.field-wrap{
	display:flex;
	position:relative;
	.field{
		border:none;
		margin:0;
		background:transparent;
		color:$color-gris-texte;
		flex-grow:1;
		margin-top:0;
		margin-bottom:0;
	}
	button{
		border:none;
		background:transparent;
		color:$color-gris-27;
		padding:0 6px;
		i{
			font-size:100%;
		}
	}
}
.filelist{
	flex:0 0 100%;
	list-style:none;
	margin:0;
	padding:0;
}
.flex-label{
	.filelist{
		padding-left:240px;
		font-size:1.3rem;
		li{
			.icon-filtres-fichier{
				color:$color-vert-apave;
			}
			margin-bottom:4px;
			a{
				color:$color-gris-texte;
			}
		}
		button{
			background:none;
			border:none;
			padding:0;
			margin:0;
			display:inline-block;
		}
	}
}
input[type="checkbox"],
input[type="radio"]{
	@extend .sr-only;
}
input[type="checkbox"] + label,
input[type="radio"] + label,
input[type="checkbox"] + .node-label,
input[type="radio"] + .node-label {
	&:before{
		@extend .fontello-base;
		font-size:1.5rem;
	}
	cursor:pointer;
	margin-right:7px;
}
input[type="checkbox"]:disabled + label,
input[type="radio"]:disabled + label,
input[type="checkbox"]:disabled + .node-label,
input[type="radio"]:disabled + .node-label{
	font-style:italic;
	opacity:0.75;
	&:before{
		opacity:0.75;
	}
}
input[type="checkbox"] + label:before,
input[type="checkbox"] + .node-label:before{
		@extend .icon-check-empty;	
}
input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:checked + .node-label:before{
	@extend .icon-ok-squared;
	color:$color-vert-apave;
}
input[type="radio"] + label:before,
input[type="radio"] + .node-label:before{
		@extend .icon-circle-empty;	
		color:$color-vert-apave;
		font-size:1.8rem;
		position:relative;
		top:2px;
		margin-left:0;
}
input[type="radio"]:checked + label:before,
input[type="radio"]:checked + .node-label:before{
	@extend .icon-dot-circled;
}
input[type="radio"]:disabled + label:before,
input[type="radio"]:disabled + .node-label:before{
	color:$color-gris-texte;
}
.checkboxes fieldset {
	margin-bottom: 5px;
}
fieldset{
	padding:0;
	& > div{
		margin:3px 0;
		align-self:flex-start;
		flex-grow:1;
		label{
			display:inline-block;
			padding-right:0;
		}
	}
	legend{
		float:left;
		&.at-top{
			margin-top:0;
		}
	}
	label{
		font-size:1.3rem;
		font-weight:normal;
		flex:0;
	}
	.field,
	.field-wrap{
		margin-right:15px;
	}
	&.statuts{
		&.conformity {
			& > div{
				display:flex;
				flex-wrap:wrap;
				flex:0 0 53%;
			}
			input[type="checkbox"] + label,
			input[type="radio"] + label{
				flex:0 0 32%;
			}
		}
		input[type="checkbox"] + label,
		input[type="radio"] + label{
			@extend span.statut;
			@extend span.statut.border;
			background-color:$color-blanc;
			border-width:1px !important;
			padding:1px 7px;
			color:$color-gris-60;
			margin-right:1px;
		}
		input[type="checkbox"] + label:before,
		input[type="radio"] + label:before{
			display:none;
		}

		input[type="checkbox"] + label:hover,
		input[type="radio"] + label:hover{
			background-color:$color-gris-25;
			border-color:$color-gris-60 !important;
		}

		input[type="checkbox"]:checked + label,
		input[type="radio"]:checked + label{
			border-color:$color-gris-60 !important;
			border-width:2px !important;
			padding:0 7px;
		}
	}
	&.degres{
		input[type="checkbox"] + label,
		input[type="radio"] + label{
			@extend span.statut;
			@extend span.statut.small;
			background-color:$color-blanc;
			color:$color-gris-texte;
			position:relative;
			margin-right:1px;
		}
		input[type="checkbox"] + label:before,
		input[type="radio"] + label:before
		{
			display:none;
		}
		input[type="checkbox"]:checked + label.degre,
		input[type="radio"]:checked + label.degre
		{
			&:before{
				@extend .fontello-base;
				@extend .icon-general-carat;
				position:absolute;
				bottom:-7px;
				left:7px;
				transform:rotate(180deg);
				font-size:0.5rem;
			}
		}
	}
	&.dropdown{
		flex-direction:column;
		position:relative;
		legend{
			float:none;
			cursor:pointer;
			text-align:left;
			&:before{
				@extend .fontello-base;
				@extend .icon-general-carat;
				position:absolute;
				right:0;
				font-size:1rem;
			}
		}
		& > div{
			display:none;
			position:absolute;
			top:100%;
			margin-top:0;
			border-radius:4px;
			z-index:10;
			background:$color-blanc;
			ul{
				margin:0;
				padding:0;
				list-style:none;
				min-width:250px;
				text-align:left;
				& > li{
					border-bottom:1px solid $color-gris-40;
					margin:0;
					padding:4px;
					display:flex;
					justify-content:flex-start;
					label{
						flex-grow:1;
						text-align:left;
					}
					button{
						justify-self:flex-end;
						padding:0;
						font-size:0.7rem;
						position:relative;
						top:2px;
					}
				}
				& + div{
					padding:7px;
				}
			}
		}
		&:hover{
			& > div{
				display:block;
			}
		}
	}
}
select.field{
	padding:0px 40px 0px 10px;
	background:transparent;
	cursor:pointer;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	position:relative;
	z-index:2;
	min-height:unset;
	& + label{
		width:auto;
		display:inline-block;
		position:absolute;
		top:0;
		right:0;
		z-index:1;
		font-size:1rem;
		padding-right:15px;
		i{
			font-size:100%;
		}
	}
}
textarea{
	height:300px;
	&.field{
		line-height:1.8rem;
		padding-top:7px;
	}
}
.ck-editor{
	flex-grow:1;
}
.ck-editor__main {
	color:black;
	font-size:1.3rem;
}

header{
	input[type="checkbox"] + label, input[type="radio"] + label{
		display:inline-block;
	}
	input[type="checkbox"] + label{
		&:before{
			background:white;
		}
	}
	.field-wrap,
	.field{
		line-height:150%;
		background:transparent;
		border:none;
		display:inline-flex;
		color:$color-blanc;
		.field{
			color:$color-blanc;
			font-weight:normal;
			option{
				color:$color-gris-texte;
			}
		}
	}
	select.field{
		padding-right:30px;
		& + label{
			font-size:0.75rem;
			top:2px;
		}
	}

	fieldset{
		&.dropdown{
			margin-left:7px;
			legend:before{
				margin-top:5px;
			}
			& > div{
				background:$color-blanc;
				color:$color-gris-texte;
				padding:3px 7px;
				min-width:100px;
				right:0;
			}
			label{
				width:100%;
				margin-bottom:3px;
			}
			ul{
				label{
					width:auto;
				}
			}
		}
	}
}
.batch-dropdown-tree {
	display: inline-block;
	background-color: $color-blanc;
	label {
		color: $color-gris-texte;
	}
	i.toggle {
		color: $color-gris-texte;
	}
}

.react-datepicker__day--today {
	color: white;
	background-color: #216ba5;
	border-radius: 0.3em;
}
.react-datepicker__day--selected {
	color: white;
	background-color: #216ba5;
	border-radius: 0.3em;
}
.react-datepicker__day--keyboard-selected {
	color: black;
	background-color: white;
	border-radius: 0;
}
.field.react-dropdown-tree-select {
	max-width: 70%;
}